<template>
  <div class="pop-over">
    <div ref="pop-button"  class="pop-button" @click="toggleOpen">
      <slot name="reference" ></slot>
    </div>
    <div v-click-out-side="close" v-show="open" class="pop-list" ref="pop-list">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {colorLog} from "../util";

export default {
  name: 'ContractPopover',
  props:['left'],
  data() {
    return {
      open: false,
      documentHandlerBond:null
    }
  },
  mounted() {
    if(this.left!=null){
      this.$refs["pop-list"].style.left=this.left;
    }
  },
  beforeUnmount() {
  },
  methods: {
    toggleOpen: function() {
      this.open = !this.open;
      this.$refs["pop-list"].style.top=this.$refs["pop-button"].offsetHeight
    },
    close: function(e) {
      if(this.$el.contains(e.target)) return;
      this.open = false;
    },
    documentHandler(el, binding,e){
      if ( el.contains(e.target)) return;
      binding.value(e);
    }
  },
  directives: {
    clickOutSide:{
      created(el,binding){
        let that = binding.instance;
        that.$data.documentHandlerBond = that.documentHandler.bind(that,el,binding);
      },
      beforeMount: function (el, binding, vnode) {
        let that = binding.instance;
        setTimeout(() => {
          document.addEventListener('click', that.$data.documentHandlerBond);
        }, 0);
      },
      beforeUnmount(el, binding, vnode) {
        let that = binding.instance;
        document.removeEventListener('click', that.$data.documentHandlerBond);
      }
    }
  }
}
</script>

<style scoped>
.pop-over {
  position: relative;
  display: inline-block;
  height: 100%;
  z-index: 999;
}
.pop-button {
  position: relative;
  display: inline-block;
  height: 100%;
  text-decoration:none;
  color: inherit;
}
.pop-list {
  position: absolute;
  left: 0;
  top: calc(100% + 1ch);
  background: white;
  z-index: 999;
  padding: 4px;
  box-shadow: var(--box-shadow-1);
  border-radius: 4px;
  min-width:100%;
  box-sizing: border-box;
}
.pop-list li {
  width: 100%;
  height: 100%;
  padding: 8px 3px;
  list-style:none;
  box-sizing: border-box;
}
</style>
